import React from "react"

import Product from "../Gallery/product"
import styles from "../../css/para-ella-component.module.css"

const ParaElla = ({ data }) => {
  return (
    <div className={styles.center}>
      {data.map(({ node }) => {
        return <Product key={node.contentful_id} product={node} />
      })}
    </div>
  )
}

export default ParaElla
