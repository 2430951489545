import React from "react"
import styles from "../../css/search-box-component.module.css"

export const SearchBox = ({ placeholder, handleChange }) => (
  <div>
    <input
      className={styles.search}
      type="search"
      placeholder={placeholder}
      onChange={handleChange}
    />
  </div>
)
