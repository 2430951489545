import React from "react"
import styles from "../../css/product.module.css"
import { Link } from "gatsby-plugin-intl"
import Image from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"
const Product = ({ product }) => {
  const { nombre, slug, etiquetas, imagenes } = product
  let mainImage = imagenes[0].fluid
  return (
    <article className={styles.product}>
      <div className={styles.customRow}>
        <div className={styles.tab}>
          <div className={styles.customRow}>
            <div className={styles.boxTab}>
              <div
                className={`${styles.effect} ${styles.effectTwo} ${styles.gridWidth6} `}
              >
                <Link to={`/galeria/${slug}`} className={styles.link}>
                  <Image
                    fluid={mainImage}
                    className={styles.img}
                    alt={"Panama Hat"}
                  />
                </Link>
                <div className={styles.tabText}>
                  <h2>{nombre}</h2>
                  <div className={`${styles.iconLinks} ${styles.p}`}>
                    {etiquetas.map((item, index) => {
                      return index > 0 && index <= 3 ? (
                        <div key={index} className={styles.tags}>
                          <i>{item.etiqueta}/</i>
                        </div>
                      ) : null
                    })}
                    <i></i>
                  </div>
                  <Link to={`/galeria/${slug}`} className={styles.link}>
                    <p className={`${styles.description} ${styles.col6}`}>
                      <FormattedMessage id="verDetalles" />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}

export default Product
